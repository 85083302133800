import { Contract } from "@ethersproject/contracts";

export function sortAddress(add) {
  const sortAdd = `${add.slice(0, 6)}...${add.slice(add.length - 4)}`;
  return sortAdd;
}

export function getSigner(library, account) {
  return library.getSigner(account).connectUnchecked();
}

export function getProviderOrSigner(library, account) {
  return account ? getSigner(library, account) : library;
}

export function getContract(address, ABI, library, account) {
  return new Contract(address, ABI, getProviderOrSigner(library, account));
}
export const calculateTimeLeft = (endDate) => {
  if (endDate) {
    let difference = endDate * 1000 - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  } else {
    return false;
  }
};


export const getColor= (requestType)=>{
  if (requestType?.toLowerCase().includes("withdraw")) {
    return "red";
  } else if (
    requestType?.toLowerCase().includes("deposit") 
    ||
    requestType?.toLowerCase().includes("subscription purchase") ||
    requestType?.toLowerCase().includes("renewal")
  ) {
    return "#38c592";
  } else {
    return "gray";
  }
}


export const getType =(requestType)=>{
  if (requestType?.toLowerCase().includes("withdraw")) {
    return "Withdraw";
  } else if (
    requestType?.toLowerCase().includes("deposit") 
    ||
    requestType?.toLowerCase().includes("subscription purchase") ||
    requestType?.toLowerCase().includes("renewal")
  ) {
    return "Deposit";
  } else {
    return requestType || "--";
  }
}


// old One for phtsical one and physical 2 cards in subscription Management
// export const findCardType =(type)=>{
//   if(type.includes("virtual")){
//     return "Virtual"
//   }else if(type.includes("physical1")){
//     return "Physical"
//   }else{
//     return "Physical 1"
//   }
// }
export const findCardType =(type)=>{
  if(type.includes("Virtual")){
    return "Virtual"
  }
  else if(type.includes("Physical2")){
    return "Physical 2"
  }
  else if(type.includes("Physical1") || type.includes("Physical1")){
    return "Physical 1"
  }
  
}

export const isSameUser = (currentMessage = {}, previousMessage = {}) => {
  const currentUserId = currentMessage?.user?._id;
  const previousUserId = previousMessage?.user?._id;
  return currentUserId === previousUserId;
};

export const isSameDay = (currentMessage = {}, previousMessage = {}) => {
  const currentDate = currentMessage?.createdAt;
  const previousDate = previousMessage?.createdAt;

  return (
      currentDate.getFullYear() === previousDate.getFullYear() &&
      currentDate.getMonth() === previousDate.getMonth() &&
      currentDate.getDate() === previousDate.getDate()
  );
};

export const validateField = (name, value) => {
  switch (name) {
    case "firstName":
    case "lastName":
      if (!value.trim()) return "This field is required.";
      if (value.length < 3) return "Must be at least 3 characters.";
      return "";
    case "email":
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!value.trim()) return "Email is required.";
      if (!emailRegex.test(value)) return "Enter a valid email address.";
      return "";
    case "mobileNumber":
      const mobileRegex = /^[0-9]{6,12}$/;
      if (!value.trim()) return "Mobile number is required.";
      if (!mobileRegex.test(value)) return "Enter a valid phone number.";
      return "";
    case "passcode":
      const passcodeRegex = /^[0-9]{6}$/;
      if (!value.trim()) return "Passcode is required.";
      if (!passcodeRegex.test(value))
        return "Passcode must be a 6-digit number.";
      return "";
    case "countryCode":
      const countryCodeRegex = /^[0-9]{2,5}$/;
      if (!value.trim()) return "Country code is required.";
      if (!countryCodeRegex.test(value))
        return "Country code must be a number between 2 and 5 digits.";
      return "";
    default:
      return "";
  }
};

export const getAmountHandler = (type) => {
  if (
    type?.transactionType == "DEPOSITPICKUP" &&
    type?.purpose == "Amount deposited"
  ) {
    return type?.amount;
  } else if (
    type?.transactionType == "DEPOSITPICKUP" &&
    type?.purpose == "Added By Driver"
  ) {
    return type?.subscriptionId?.deliveryFee;
  } else {
    switch (type?.transactionType) {
      case "RENEWAL":
        return type?.amount;
      case "SUBSCRIPTIONPURCHASE":
        return type?.amount;
      case "TRANSFERPICKUP":
        return type?.subscriptionId?.deliveryFee;
      case "DEPOSIT via card":
        return type?.deliveryFee;
      case "DEPOSITPICKUP":
        return type?.deliveryFee;
      case "INTERNATIONAL":
        return type?.deliveryFee;
      case "PENALTY_PAYMENT":
        return type?.amount;
      default:
        return type?.amount;
    }
  }

  // return type?.penaltyAmount ??
};

const TRANSACTION_TYPE_MAPPING = {
  All: ["WITHDRAW", "RENEWAL", "SUBSCRIPTIONPURCHASE", "SUBSCRIPTIONPURCHASEUPGRADE", "UNLOCKED", "Withdraw By Head Office", "Card Buy", "Withdraw By Head Office", "USDT Exchange", "Additional Fees", "By OMT", "Rival Team Rebate", "USDT Exchange", "By Driver", "DEPOSIT", "RENEWAL", "SUBSCRIPTION", "LOCKED", "TRANSFER"],
  DEPOSIT: ["DEPOSIT", "DEPOSITPICKUP", "DEPOSITTRANSFER", "Card Buy", "By Head Office", "By Whish", "By OMT", "Rival Team Rebate", "USDT Exchange", "DEPOSITTRANSFER", "By Driver", "SUBSCRIPTIONPURCHASEUPGRADE","SUBSCRIPTIONPURCHASE"],
  WITHDRAW: ["WITHDRAW", "USDT exchange", "By driver", "Withdraw via pickup", "Withdraw By Head Office", "Additional Fees", "RENEWAL", "DEPOSITTRANSFER","TRANSFERPICKUP", "PENALTY_PAYMENT"],
  TRANSFER: ["LOCKED", "TRANSFER", "ADDAMOUNT", "CARDBUY", "UNLOCKED"],
  // Add other mappings if needed
  };

  export const returnType = (type) => {
    console.log("asdsdasd",type)
    if (TRANSACTION_TYPE_MAPPING.DEPOSIT.includes(type)) {
      return "DEPOSIT";
    } else if (TRANSACTION_TYPE_MAPPING.WITHDRAW.includes(type)) {
      return "WITHDRAW";
    } else if (TRANSACTION_TYPE_MAPPING.TRANSFER.includes(type)) {
      return "TRANSFER";
    } else {
      return "UNKNOWN"; // Default for unmapped types
    }
  };

 export const returnAmount = (test) => {
    return parseFloat(test).toFixed(2).split(".")
  }